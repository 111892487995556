@font-face {
  font-family: 'a_love_of_thunderregular';
  src: url('a_love_of_thunder-webfont.woff2') format('woff2'),
       url('a_love_of_thunder-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'a_love_of_thunderregular', 'sans serif';
}


/* REVIEW */


#map-plug {display:none;}


.col-md-12, .service-package {
  background-color: #010606;
  color: whitesmoke;
  padding-top: 2em;
}
 
#google-reviews {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  padding: 0 2em;
  gap: 1.5em;
  background-color: #010606;
  color: black;
}

span.review-profile-image {
    float: left;
        padding: 0px 15px 0px 0px;
}

span.review-profile-image img {
    width: 40px;
}

.modal-backdrop.in{display:none;}
.review-item {
    margin: 5px auto;
    display: block;
    width: 100%;
    padding: 15px;
    border-radius: 10px;
    background-color: lightgray;
}
 
.review-meta, .review-stars {text-align:left; font-size:115%;}
.review-author { text-transform: capitalize; font-weight:bold; }
.review-date {opacity:.6; display:block;}
.review-text {  line-height: 1.55;
    text-align: left;
    max-width: 72em;
    margin: auto;}
 
 
 
.review-stars ul {
display: inline-block;
list-style: none !important;
margin:0; padding:0;
}
 
.review-stars ul li {
float: left;
list-style: none !important;
margin-right: 1px;
line-height: 1;
}
 
.review-stars ul li i {
  color: #E4B248;
  font-size: 1.4em;
  font-style:normal;
}

.review-stars ul li i.inactive { 
  color: #c6c6c6;
}
.star:after { 
  content: "\2605"; 
}

.review-sep {
  display: none;
}

.star img {
  width: 2em;
  height: 100%;
}


/* FOOTER */

.mini-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  position: sticky;
  font-size: 12px;
  background-color: #010606;
  padding: 2em 0;
  width: 100%;
}

.mini-footer a {
text-decoration: none;
color: #8f9296;
}

.mini-footer a:hover {
  text-decoration: none;
  color: #8f9296;
}

.mini-footer span {
  color: #8f9296;
}

/* RETAIL IMAGES */

.retail {
  height: 100%;
  width: 200px;
}

@media screen and (max-width: 768px) {
  .retail {
    height: 100%;
    width: 65px;
    margin-top: -2em;
  }
}

.insta {
  background-color: black;
  padding: 3em 0;
}


/* LOCATIONS */

.locations {
  background-color: lightgray;
  display: flex;
  flex-direction: row;
  gap: 8em;
  height: 1150px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: 1.4px;
}

.locationTitle {
  margin-top: 1.5em;
}

.retail-pic {
  max-width: 455px;
  height: auto;
}

@media screen and (max-width: 768px) {
  .locations {
    flex-direction: column;
  }

  .retail-pic {
    max-width: 300px;
    margin-bottom: -4em;
  }
}


/* SOCIAL ICONS */

.social-container {
  background: transparent;
  padding: 25px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

a.social {
  margin: 0 1rem;
  font-size: 1.25em;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

a.facebook {
  color: white;
}

a.instagram {
  color: white;
}